import { ReactFlowProvider } from "reactflow";
import "./App.css";
import Parent from "./Components/Parent";
import Topbar from "./Components/Topbar/Topbar";
import { useState } from "react";
import Login from "./Components/SSOscreen";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  return (
    <ReactFlowProvider>
      {isAuthenticated ? (
        <>
          <Topbar />
          <Parent />
        </>
      ) : (
        // <SSOscreen authentication={(value) => setIsAuthenticated(value)} />
        <Login authentication={(value) => setIsAuthenticated(value)}/>
      )}
    </ReactFlowProvider>
  );
}

export default App;
