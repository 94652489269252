import { useState } from "react";
import { Handle, Position } from "reactflow";
import "./result-node.css";
import { useStore } from "../../store";
import AddIcon from "../../../assets/Images/Icon.svg";
import { handleNewTableClick } from "../../utils/addtable";
import { handleJoinTableClick } from "../../utils/jointable";
import { Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useResizeObserver } from "@mantine/hooks";

const Result = ({ id }) => {
  const [showButtons, setShowButtons] = useState(false);
  const [ref, rect] = useResizeObserver();
  const { flow, addNode, addEdge, updateTableNames, updateResultNode, options } = useStore();


  const filterResultNode = flow?.nodes.filter((node) => node.id === id);

  const form = useForm({
    initialValues: filterResultNode.length > 0 ? filterResultNode[0]?.formValues : {
      resultSet: "",
      result: "",
    },
  });

  const handleAddIconClick = () => {
    setShowButtons(!showButtons);
    if (!options.tableNames.includes(form.values.tableName)) {
      updateTableNames(form.values.tableName);
    };
    updateResultNode(id, form.values, rect["width"], rect["height"])
  }

    return (
      <div className="formContainer" ref={ref}>
        <Handle type="source" position={Position.Top} id="a" />
        <form className="result-form">
          <div className="result-node-container">
            <Textarea placeholder="Result Set" data="Result Set" autosize minRows={1} {...form.getInputProps("tableName")} />
            <div className="result-image">
              {form.values.tableName &&
                <img src={AddIcon} onClick={handleAddIconClick} alt="Add Icon" />}
              {showButtons && (
                <div className="Button-container">
                  <button
                    onClick={(e) => {
                      setShowButtons(!showButtons)
                      handleNewTableClick(flow.nodes, addNode);
                      e.preventDefault();
                    }}
                  >
                    New Table
                  </button>
                  <button
                    onClick={(e) => {
                      setShowButtons(!showButtons)
                      handleJoinTableClick(addNode, addEdge, id, form.values.tableName);
                      e.preventDefault();
                    }}
                  >
                    Join Table
                  </button>
                </div>
              )}
            </div>
          </div>
          <Textarea autosize minRows={3} {...form.getInputProps("result")} />
        </form>
        <Handle type="target" position={Position.Left} id="b" />
      </div>
    );
  };

export default Result;
