import elk from "elkjs/lib/elk.bundled.js";

export async function autolayout(nodes, edges) {
  const elkLayoutOptions = {
    id: "root",
    children: nodes.map((node) => {
      //   console.log(node);

      if (!node.id) return; 

      return {
        id: node.id,
        width: node.elkLayout ? node.elkLayout.width + 120 : null,
        height: node.elkLayout ? node.elkLayout.height + 120 : null,
      };
    }),
    edges: edges.map((edge) => {
            return {
        id: edge.id,
        sources: [edge.source],
        targets: [edge.target],
      };
    }),
    layoutOptions: {
      "elk.algorithm": "layered",
      "elk.direction": "RIGHT",
      "elk.layered.nodeOrdering": "PREDEFINED",
      "elk.layered.nodePlacement.strategy": "NETWORK_SIMPLEX",
      "elk.layered.spacing.nodeNodeBetweenLayers": 100,
      "elk.spacing.nodeNode": 80,
    },
  };
  const Elk = new elk();
  const elkGraph = await Elk.layout(elkLayoutOptions);

  const updatedNodes = elkGraph.children.map((elkNode) => {
    const node = nodes.find((n) => n.id === elkNode.id);
    return {
      ...node,
      position: {
        x: elkNode.x,
        y: elkNode.y,
      },
    };
  });

  return updatedNodes;
}
