import { addEdges } from "./addEdge";
import { v4 as uuidv4 } from "uuid";

export async function handleJoinTableClick(
  addNode,
  addEdge,
  id,
  tableName,
  formValues
) {
  const joinTableNode = {
    id: `joinNode_${uuidv4()}`,
    type: "joinNode",
    position: { x: 0, y: 0 },
    formValues: formValues ? formValues : { joinTables: [], joinCondition: "" },
    style: {
      width: "auto",
      height: "auto",
      borderRadius: "8px",
    },
    elkLayout: {
      width: 0,
      height: 0,
    },
    target: "target",
    tableName,
  };

  const edge = addEdges(id, joinTableNode.id, "target");
  addEdge(edge);
  addNode(joinTableNode);
}
