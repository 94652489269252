import { v4 as uuidv4 } from "uuid";

export const initalState = {
  id: "tableNode_" + uuidv4(),
  type: "form",
  position: { x: 30, y: 60 },
  data: { label: null },
  editStatus: "clean",
  editable: true,
  formValues: {
    tableName: "",
    description: "",
    select: "",
    where: "",
    groupBy: "",
    isDrivingTable: true,
  },
  joinNodeValues: [],
  style: { width: 250, height: 500, borderRadius: "8px" },
  elkLayout: {
    width: 0,
    height: 0,
  }
};
