
import React, { useEffect, useState } from "react";
import {
  Handle,
  Position,
  NodeResizer,
  NodeToolbar,
  useNodes,
  useReactFlow,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
} from "reactflow";
import AddIcon from "../../../assets/Images/AddIcon.svg";
import { useStore } from "../../store";
import "./new-table-node.css";
import { handleNewTableClick } from "../../utils/addtable";
import { handleJoinTableClick } from "../../utils/jointable";
import { useForm } from "@mantine/form";
import { Select, Textarea, Switch, Button, ActionIcon } from "@mantine/core";
import { v4 as uuidv4 } from "uuid";
import removeEdge from "../../utils/removeEdge";
import {
  IconSettings2,
  IconTrash,
  IconCopy,
  IconPencil,
} from "@tabler/icons-react";
import removeNodes from "../../utils/removeNode";
import { useResizeObserver } from "@mantine/hooks";

const Form = ({ id }) => {
  const [showButtons, setShowButtons] = useState(false);
  const [showToolbar, setShowToolbar] = useState(false);
  const { getNode, deleteElements, getEdges, getNodes } = useReactFlow();
  const node = getNode(id);
  const [ref, rect] = useResizeObserver();

  const {
    flow,
    addNode,
    addEdge,
    setEditable,
    setEdges,
    setNodes,
    updateTableNode,
    updateTableNames,
    options,
  } = useStore();

  const form = useForm({
    initialValues: {
      tableName: "",
      description: "",
      select: "",
      where: "",
      groupBy: "",
      isDrivingTable: true,
    },
  });

  useEffect(() => {
    form.setValues(node.formValues);
    setEditable(id, true);
  }, []);

  const handleOkayClick = async () => {
    const updatedNode = (node.editStatus = "dirty");
    updateTableNode(
      id,
      form.values,
      updatedNode,
      false,
      rect["width"],
      rect["height"]
    );
    if (!options.tableNames.includes(form.values.tableName)) {
      updateTableNames(form.values.tableName);
    }
  };

  const handleAddIconClick = () => {
    setShowButtons(!showButtons);
  };

  const handleEdit = () => {
    setEditable(id, true);
    setShowToolbar(false);
  };

  const handleDelete = () => {
    const conectedEdges = getConnectedEdges([node], flow.edges);
    console.log(getOutgoers(node,flow.nodes,flow.edges))
    removeEdge(setEdges, flow.edges, conectedEdges);
    removeNodes(setNodes, flow.nodes, node);
    setShowToolbar(false);
  };

  const handleCopy = () => {
    handleNewTableClick(node.formValues, addNode);
    setShowToolbar(false);
  };

  return (
    <>
      <NodeToolbar isVisible={showToolbar && !node.editable}>
        <div className="toolbar">
          <ActionIcon size="lg" radius="xl" variant="filled" color="red">
            <IconTrash onClick={handleDelete} />
          </ActionIcon>
          <ActionIcon size="lg" radius="xl" variant="filled" color="blue">
            <IconCopy onClick={handleCopy} />
          </ActionIcon>
          <ActionIcon size="lg" radius="xl" variant="filled" color="blue">
            <IconPencil onClick={handleEdit} />
          </ActionIcon>
        </div>
      </NodeToolbar>
      <div
        className="form-container"
        style={{
          backgroundColor: form.values.isDrivingTable ? "#4472C4" : "#A9D18E",
        }}
        ref={ref}
      >
        {!node.editable ? (
          <div style={{ textAlign: "right" }}>
            <IconSettings2
              onClick={() => setShowToolbar(!showToolbar)}
              color="white"
              style={{ cursor: "pointer" }}
            />
          </div>
        ) : (
          <></>
        )}
        {/* <Handle type="target" position={Position.Left} id={uuidv4()} /> */}
        <form
          className="form"
          // onSubmit={form.onSubmit((values) => console.log(values))}
        >
          <Select
            placeholder="Select/Alias"
            label="Select/Alias"
            {...form.getInputProps("tableName")}
            data={[
              { value: "SpecificInheritanceRelationship(SIR)", label: "SpecificInheritanceRelationship(SIR)" },
              { value: "SpecHeader(SH_Parent)", label: "SpecHeader(SH_Parent)" },
              { value: "SpecHeader(SH_Child)", label: "SpecHeader(SH_Child)" },
              { value: "SpecHeader", label: "SpecHeader" },
              { value: "SpecCharacteristicValue(PAM_BUOM)", label: "SpecCharacteristicValue(PAM_BUOM)" },
              { value: "SpecCharacteristicValue(PAM_UOM)", label: "SpecCharacteristicValue(PAM_UOM)" },
              { value: "UOMRD (UOM_Parent)", label: "UOMRD (UOM_Parent)" },
              { value: "UOMRD (UOM_Child)", label: "UOMRD (UOM_Child)" },
              { value: "PropertyValue (PV_ComponentClass)", label: "PropertyValue (PV_ComponentClass)" },
              { value: "PropertyValue (PV_TCFrameName)", label: "PropertyValue (PV_TCFrameName)" },
              { value: "PAMSpecificationProperty", label: "PAMSpecificationProperty" },
              { value: "UOMRD (UOM_PAMBase)", label: "UOMRD (UOM_PAMBase)" },
              { value: "UOMRD (UOM_WeightLabel)", label: "UOMRD (UOM_WeightLabel)" },
              { value: "UOMRD (UOM_WeightCode)", label: "UOMRD (UOM_WeightCode)" },
              { value: "UOMRD (UOM_WeightPerUnit)", label: "UOMRD (UOM_WeightPerUnit)" },
              { value: "ZplmTPlntFrbPreferred", label: "ZplmTPlntFrbPreferred" },
            ]}
            className="nodrag"
            readOnly={!node.editable}
          />
          {!node.editable && form.values.description === "" ? null : (
            <Textarea
              placeholder="Description"
              autosize
              {...form.getInputProps("description")}
              minRows={1}
              readOnly={!node.editable}
              label="Description"
            />
          )}
          {!node.editable && form.values.select === "" ? null : (
            <Textarea
              placeholder="Select"
              autosize
              {...form.getInputProps("select")}
              minRows={1}
              readOnly={!node.editable}
              label="Select"
            />
          )}
          {!node.editable && form.values.where === "" ? null : (
            <Textarea
              placeholder="Where"
              autosize
              {...form.getInputProps("where")}
              minRows={1}
              readOnly={!node.editable}
              label="Where"
            />
          )}
          {!node.editable && form.values.groupBy === "" ? null : (
            <Textarea
              placeholder="Group By"
              autosize
              {...form.getInputProps("groupBy")}
              minRows={1}
              readOnly={!node.editable}
              label="Group By"
            />
          )}
          {!node.editable && (
            <div className="image-container">
              <img
                src={AddIcon}
                onClick={(e) => {
                  handleAddIconClick();
                  e.preventDefault();
                }}
                alt="Add Icon"
              />
              {showButtons && (
                <div className="butt-container">
                  <button
                    onClick={(e) => {
                      handleNewTableClick(null, addNode);
                      handleAddIconClick();
                      e.preventDefault();
                    }}
                  >
                    New Table
                  </button>
                  <button
                    onClick={(e) => {
                      handleJoinTableClick(
                        addNode,
                        addEdge,
                        id,
                        form.values.tableName
                      );
                      handleAddIconClick();
                      e.preventDefault();
                    }}
                  >
                    Join Table
                  </button>
                </div>
              )}
            </div>
          )}
          {node.editable && (
            <>
              <div style={{ margin: "1rem 0" }}>
                <Switch
                  labelPosition="left"
                  label="Driving Table"
                  size="lg"
                  onLabel="No"
                  offLabel="Yes"
                  readOnly={!node.editable}
                  {...form.getInputProps("isDrivingTable", {
                    type: "checkbox",
                  })}
                />
              </div>
              <div>
                <Button
                  style={{
                    backgroundColor: form.values.isDrivingTable
                      ? "#4472C4"
                      : "#A9D18E",
                    margin: "0 0.5rem",
                    border: "1px solid #fff",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  style={{
                    backgroundColor: form.values.isDrivingTable
                      ? "#4472C4"
                      : "#A9D18E",
                    margin: "0 0.5rem",
                    border: "1px solid #fff",
                  }}
                  onClick={(e) => {
                    handleOkayClick();
                    e.preventDefault();
                  }}
                  disabled={!form.isDirty()}
                >
                  Okay
                </Button>
              </div>
            </>
          )}
        </form>
        <Handle type="source" position={Position.Right} id={uuidv4()} />
      </div>
    </>
  );
};

export default Form;
