import React, { useEffect, useState } from "react";
import {
  Handle,
  Position,
  useReactFlow,
  getConnectedEdges,
  getOutgoers,
  getIncomers,
} from "reactflow";
import { Select, Textarea, Button, ActionIcon } from "@mantine/core";
import AddIcon from "../../../assets/Images/Join.svg";
import { v4 as uuidv4 } from "uuid";
import { useForm } from "@mantine/form";
import { useStore } from "../../store";
import { IconTrash } from "@tabler/icons-react";
import "./join-node.css";
import { addEdges } from "../../utils/addEdge";
import { handleResultTable } from "../../utils/resultTable";
import { useResizeObserver } from "@mantine/hooks";
import removeEdge from "../../utils/removeEdge";
import removeMultipleNodes from "../../utils/removeMultipleNodes";

const Join = ({ id }) => {
  const {
    flow,
    addNode,
    addEdge,
    options,
    updateJoinNodeValues,
    setEdges,
    setNodes,
  } = useStore();
  const [showButtons, setShowButtons] = useState(true);
  const [ref, rect] = useResizeObserver();
  const { getNode } = useReactFlow();
  const node = getNode(id);

  const getTableNameById = (id) => {
    const node = flow.nodes.find((node) => node.id === id);
    return node ? node.tableName : null;
  };

  const tableName = getTableNameById(id);

  const form = useForm({
    initialValues:
      node.formValues.joinTables.length > 0
        ? node.formValues
        : {
            joinTables: [
              {
                tableName: tableName,
                description: "",
              },
              {
                tableName: "",
                description: "",
              },
            ],
            joinCondition: "",
          },
  });

  function handleOkay() {
    const connectedEdges = getConnectedEdges([node], flow.edges);
    removeEdge(setEdges, flow.edges, connectedEdges);

    const { joinTables } = form.values;

    joinTables.forEach((table, index) => {
      const newTableName = table.tableName;

      if (newTableName.trim() !== "") {
        let sourceNodeId = flow.nodes.find(
          (node) => node.formValues?.tableName === newTableName
        );
        if (sourceNodeId) {
          console.log(sourceNodeId);
          const edge = addEdges(sourceNodeId.id, id, `target-${index}`);
          addEdge(edge);
        }
      }
    });
    const arr = [];
    flow.nodes.forEach((item) => {
      let flag = true;
      flow.edges.forEach((ele) => {
        if (ele.target === item.id || ele.source === item.id) {
          flag = false;
        }
      });
      if (flag && item.id.includes("resultNode")) {
        arr.push(item.id);
      }
    });
    removeMultipleNodes(setNodes, flow.nodes, arr);
    handleResultTable(addNode, addEdge, id);
    updateJoinNodeValues(id, form.values, rect["width"], rect["height"]);
  }

  const handleAddIconClick = () => {
    const newTable = {
      tableName: "",
      description: "",
    };

    form.setValues({
      joinTables: [...form.values.joinTables, newTable],
    });

    setShowButtons(true);
  };

  // const getSecondDropdownOptions = () => {
  //   const options = flow.nodes
  //     .filter((node) => node.formValues && node.formValues.tableName)
  //     .map((node) => ({
  //       value: node.formValues.tableName,
  //       label: node.formValues.tableName,
  //     }));
  //   return options;
  // };

  const handleDeleteItem = (index) => {
    form.removeListItem("joinTables", index);
  };

  const secondDropdownOptions = options.tableNames.map((tableName) => ({
    value: tableName,
    label: tableName,
  }));

  return (
    <div className="join-form-container" ref={ref}>
      <Handle type="target" position={Position.Left} id={uuidv4()} />
      <form>
        <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <Select
            {...form.getInputProps(`joinTables.0.tableName`)}
            placeholder="Select/Alias"
            data={secondDropdownOptions}
            className="nodrag"
          />
          <p>AS</p>
          <Textarea
            {...form.getInputProps(`joinTables.0.description`)}
            placeholder="Description"
            autosize
            minRows={1}
          />
        </div>

        {form.values.joinTables.slice(1).map((item, index) => (
          <div
            key={index}
            style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
          >
            <Select
              {...form.getInputProps(`joinTables.${index + 1}.tableName`)}
              placeholder="Select/Alias"
              data={secondDropdownOptions}
              className="nodrag"
            />
            <p>AS</p>
            <Textarea
              {...form.getInputProps(`joinTables.${index + 1}.description`)}
              placeholder="Description"
              autosize
              minRows={1}
            />
            <ActionIcon
              size="lg"
              radius="xl"
              onClick={(e) => {
                handleDeleteItem(index + 1);
                e.preventDefault();
              }}
            >
              <IconTrash />
            </ActionIcon>
          </div>
        ))}
        <div className="mainImageContainer">
          <img
            src={AddIcon}
            onClick={handleAddIconClick}
            alt="Add Icon"
            className="imageContainer"
          />
        </div>

        <Textarea
          {...form.getInputProps(`joinCondition`)}
          placeholder="Join condition"
          autosize
          minRows={1}
        />
        {/* {showButtons ? ( */}
        <div className="join-buttons">
          <Button className="join-actions">Cancel</Button>
          <Button
            className="join-actions"
            onClick={(e) => {
              handleOkay();
              e.preventDefault();
            }}
          >
            Okay
          </Button>
        </div>
        {/* ) : (
          <></>
        )} */}
      </form>
      <Handle type="source" position={Position.Right} />
    </div>
  );
};

export default Join;
