import { v4 as uuidv4 } from "uuid";

export async function handleNewTableClick(formValues, addNode) {
  const newNode = {
    id: `tableNode_${uuidv4()}`,
    type: "form",
    position: { x: 0, y: 0 },
    data: { label: null },
    editStatus: "clean",
    editable: true,
    formValues:
      formValues === null
        ? {
            tableName: "",
            description: "",
            select: "",
            where: "",
            groupBy: "",
            isDrivingTable: true,
          }
        : formValues,
    style: {
      width: "auto",
      height: "auto",
      borderRadius: "8px",
    },
    elkLayout: {
      width: 0,
      height: 0,
    },
  };
  await addNode(newNode);
}
