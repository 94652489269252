import { addEdges } from "./addEdge";
import { v4 as uuidv4 } from "uuid";

export async function handleResultTable(addNode, addEdge, id) {
  const newNode = {
    id: `resultNode_${uuidv4()}`,
    type: "result",
    position: { x: 0, y: 0 },
    data: { label: null },
    style: {
      width: "auto",
      height: "auto",
      borderRadius: "8px",
      backgroundColor: "#FFC000",
    },
    elkLayout: {
      width: 0,
      height: 0,
    },
    formValues: {
      tableName: "",
      result: "",
    },
  };

  const edge = addEdges(id, newNode.id, "target");
  await addNode(newNode);
  addEdge(edge);
}
