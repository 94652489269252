import React, { useState } from "react";
import "./SSOscreen.css";
// import { TextField } from '@mui/material'

function Login({ authentication }) {
  const [pass, setPass] = useState("");

  function onClick() {
    if (pass === "d-canvas") {
      authentication(true);
    }
  }
  return (
    <div className="login">
      <div className="login-content">
        <label>Email :</label>
        <input className="input"></input>
        <label>Password :</label>
        <input
          className="input"
          type="password"
          onChange={(e) => setPass(e.target.value)}
        ></input>
        <button onClick={onClick} style={{margin:'1rem 0'}}>Login</button>
      </div>
    </div>
  );
}

export default Login;
