import { MarkerType } from "reactflow";
import { v4 as uuidv4 } from "uuid";

export function addEdges(sourceTableName, targetNodeId, targetHandle) {
  return {
    id: `edge_${uuidv4()}`,
    source: sourceTableName,
    target: targetNodeId,
    sourceHandle: "a",
    type: "smoothstep",
    targetHandle: targetHandle,
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 30,
      height: 30,
      color: "black",
    },
    animated: true,
    style: { stroke: "black" },
  };
}
