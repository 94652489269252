export default function removeEdge(setEdges,edges,connectedEdges){
    for (let i = 0; i < connectedEdges.length; i++) {
        let objToRemove = connectedEdges[i];
        
        let indexToRemove = edges.findIndex(item => item.id === objToRemove.id);
        
        if (indexToRemove !== -1) {
            edges.splice(indexToRemove, 1);
        }
    }
    setEdges(edges)
}