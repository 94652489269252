import React, { useEffect, useMemo } from "react";
import ReactFlow, { Controls, Background, Panel, MiniMap } from "reactflow";
import "reactflow/dist/style.css";
import Form from "../Components/template/NewtableNode/new-table-node";
import Result from "../Components/template/ResultNode/result-node";
import Join from "../Components/template/JoinNode/Join-node";
import { useStore } from "./store";
import { autolayout } from "./utils/autolayout";
import { ActionIcon, Tooltip } from "@mantine/core";
import {
  IconLayoutGrid,
  IconTrashXFilled,
  IconDeviceFloppy,
} from "@tabler/icons-react";
import { initalState } from "./initalState";
import "./Parent.css";

function Flow() {
  const { nodes, edges } = useStore((state) => state.flow);

  async function layouting(nodes, edges) {
    const updatedNodes = await autolayout(nodes, edges);
    useStore.setState((state) => ({
      flow: {
        ...state.flow,
        nodes: updatedNodes,
      },
    }));
  }

  const { setNodes, setEdges } = useStore();

  const setNodeInitalState = () => {
    setNodes([initalState]);
    setEdges([])
  };

  useEffect(() => {
    // const { nodes, edges } = localStorage.getItem("nodeEdgeKey")
    //   ? JSON.parse(localStorage.getItem("nodeEdgeKey"))
    //   : { nodes: null, edges: null };
    if (nodes && edges) {
      if (nodes.length && edges.length) {
        setNodes(nodes);
        setEdges(edges);
        layouting(nodes, edges);
      } else {
        setNodeInitalState();
      }
    } else setNodeInitalState();
  }, []);

  const nodeTypes = useMemo(
    () => ({
      form: Form,
      result: Result,
      joinNode: Join,
    }),
    []
  );

  //to made each node drggable
  const onNodeDrag = (event, node) => {
    const updatedNode = {
      ...node,
      position: {
        x: node.position.x + event.movementX,
        y: node.position.y + event.movementY,
      },
    };

    const updatedNodes = nodes.map((n) => (n.id === node.id ? updatedNode : n));

    useStore.setState((state) => ({
      flow: {
        ...state.flow,
        nodes: updatedNodes,
      },
    }));
  };

  // const setLocalStroage = (nodeEdgeValue) => {
  //   if (localStorage.getItem("nodeEdgeKey")) {
  //     localStorage.removeItem("nodeEdgeKey");
  //   }
  //   localStorage.setItem("nodeEdgeKey", nodeEdgeValue);
  // };

  return (
    <div className="container">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onNodeDrag={onNodeDrag}
        style={{
          zoom: 0.77,
        }}
      >
        <Panel position="top-right" style={{ marginRight: "3rem" }}>
          <div style={{ display: "flex", gap: "1.5rem" }}>
            {/* <ActionIcon
              radius="md"
              size="xl"
              variant="filled"
              color="green"
              // onClick={() => setLocalStroage(JSON.stringify({ nodes, edges }))}
            >
              <IconDeviceFloppy />
            </ActionIcon> */}
            <ActionIcon
              onClick={() => layouting(nodes, edges)}
              radius="md"
              size="xl"
              variant="filled"
              color="blue"
            >
              <IconLayoutGrid />
            </ActionIcon>
            <ActionIcon
              onClick={setNodeInitalState}
              radius="md"
              size="xl"
              variant="filled"
              color="red"
            >
              <IconTrashXFilled />
            </ActionIcon>
          </div>
        </Panel>
        <Controls />
        <Background />
        <MiniMap zoomable pannable />
      </ReactFlow>
    </div>
  );
}

export default Flow;
