import { create } from "zustand";
import { persist, createJSONStorage } from 'zustand/middleware'

export const useStore = create(
persist(
  (set,get) => ({
    flow: {
      nodes: [],
      edges: [],
    },
  
    options: {
      tableNames: [],
    },
  
    setNodes: (nodes) =>
    // {console.log(nodes)},
      set((state) => ({
        flow: {
          ...state.flow,
          nodes: [...nodes],
        },
      })),
  
    setEdges: (edges) =>
      set((state) => ({
        flow: {
          ...state.flow,
          // edges: edges
           edges: [ ...edges],
        },
      })),
  
    addNode: (node) =>
      set((state) => ({
        flow: {
          ...state.flow,
          nodes: [...state.flow.nodes, node],
        },
      })),
  
    addEdge: (edge) =>
      set((state) => ({
        flow: {
          ...state.flow,
          edges: [...state.flow.edges, edge],
        },
      })),
  
      updateTableNames: (tableNames) =>
      set((state) => ({
        options: {
          ...state.options,
          tableNames:[...state.options.tableNames,tableNames]
        },
      })),
  
    updateTableNode: (nodeId, updatedValues, updateStatus, isEditable, width, height) =>
      set((state) => ({
        flow: {
          ...state.flow,
          nodes: state.flow.nodes.map((node) =>
            node.id === nodeId
              ? {
                  ...node,
                  formValues: {
                    ...node.formValues,
                    ...updatedValues,
                  },
                  editStatus: updateStatus,
                  editable: isEditable,
                  elkLayout: {
                    width: width,
                    height: height,
                  }
  
                }
              : node
          ),
        },
      })),
  
    updateJoinNodeValues: (id, updatedValues,width,height) =>
      set((state) => ({
        flow: {
          ...state.flow,
          nodes: state.flow.nodes.map((node) =>
            node.id === id
              ? {
                  ...node,
                  formValues: {
                    ...node.formValues,
                    ...updatedValues,
                  },
                  elkLayout: {
                    width: width,
                    height: height,
                  }
                }
              : node
          ),
        },
      })),
  
    updateResultNodeValues: (id, updatedValues) =>
      set((state) => ({
        flow: {
          ...state.flow,
          nodes: state.flow.nodes.map((node) =>
            node.id === id
              ? {
                  ...node,
                  formValues: {
                    ...node.formValues,
                    ...updatedValues,
                  },
                }
              : node
          ),
        },
      })),
  
    setEditable: (nodeId, updatedStatus) =>
      set((state) => ({
        flow: {
          ...state.flow,
          nodes: state.flow.nodes.map((node) =>
            node.id === nodeId
              ? {
                  ...node,
                  editable: updatedStatus,
                }
              : node
          ),
        },
      })),
  
      updateResultNode: (nodeId, updatedValues,width,height) =>
      set((state) => ({
        flow: {
          ...state.flow,
          nodes: state.flow.nodes.map((node) =>
            node.id === nodeId
              ? {
                  ...node,
                  formValues: {
                    ...node.formValues,
                    ...updatedValues,
                  },
                  elkLayout: {
                    width: width,
                    height: height,
                  }
                }
              : node
          ),
        },
      })),
  }),
  {
    name:"flow",
    storage: createJSONStorage(() => localStorage),
  }
)
);
